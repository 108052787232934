* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body {
  background-color: #f7f7f7;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
